// Navbar.js
import { useState, useContext } from 'react';
import CartModal from './CartModal';
import { CartContext } from '../context/CartContext';
import { StoreContext } from '../context/StoreContext';
import './Navbar.css';

function NavbarComponent({ storeHandle, absolutePath }) {
  const cart = useContext(CartContext);
  const { storeData } = useContext(StoreContext);
  const [show, setModalShow] = useState(false);
  const modalClose = () => setModalShow(false);
  const modalShow = () => setModalShow(true);

  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

  const homePath = absolutePath ? "/" : `/${storeHandle}`;

  const handle = storeData?.handle || '';
  const logo = storeData?.logo || '';
  const name = storeData?.name || 'Store';

  return (
    <>
      <header id="navbar" className="navbar">
        <div className="navbar-start">
          <a href={homePath}>
            {logo && (
              <img className="nav-logo" src={logo} alt="Store Logo" />
            )}
          </a>
          <a href={homePath}>
            <span className="site-name">{name}</span>
          </a>
        </div>
        <div className="navbar-end">
          <a className={`sunthar-checkout-button ${productsCount > 0 ? 'active' : ''}`} onClick={modalShow}>
            <i className="bi bi-cart"></i> Shopping Cart ({productsCount} Items)
          </a>
        </div>
      </header>
      <CartModal show={show} handleClose={modalClose} storeHandle={handle}/>
    </>
  );
}

export default NavbarComponent;