// ContactModal.js
import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { contactStoreOwner } from '../services/contactService'; 
import countryLookup from '../utils/countryLookup';
import { toast } from 'react-toastify';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; 

const ContactModal = ({ show, handleClose, storeHandle, product, storeAddress }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mapUrl, setMapUrl] = useState('');

  useEffect(() => {
    if (storeAddress && storeAddress.suburb && storeAddress.state && storeAddress.country) {
      const addressQuery = `${storeAddress.suburb}, ${storeAddress.state}, ${storeAddress.country}`;
      const size = "600x200"; // Map size (width x height in pixels)
      const zoom = 12; // Adjust zoom level for approximate location
      const scale = 2; // High resolution for sharper images

      const marker = `markers=color:0xf5a623|label:Pickup`

      // Convert styles to Static Maps format
      const style = [
        "feature:all|element:labels.text.fill|gamma:0.01|lightness:20|weight:1.39|color:0xffffff",
        "feature:all|element:labels.text.stroke|weight:0.96|saturation:9|visibility:on|color:0x000000",
        "feature:all|element:labels.icon|visibility:off",
        "feature:all|element:geometry|color:0x431C53", // Dark reddish-purple for all geometry
        "feature:road|element:geometry|color:0xff4c4c", // Bright red for roads
        "feature:road|element:geometry.stroke|color:0xea3377", // Pinkish-red for road borders
        "feature:water|element:geometry|color:0xff4c4c", // Bright red for water
        "feature:landscape|element:geometry|color:0x431C53", // Pinkish-red for landscape
        "feature:poi|element:geometry|color:0x8d1642", // Dark reddish-purple for points of interest
        "feature:poi.park|element:geometry|lightness:20|saturation:-20",
      ].join("&style=");

      const url = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
        addressQuery
      )}&zoom=${zoom}&size=${size}&scale=${scale}&maptype=roadmap&${marker}|${encodeURIComponent(
        addressQuery
      )}&style=${style}&key=${GOOGLE_MAPS_API_KEY}`;

      setMapUrl(url);
    }
  }, [storeAddress]);

  const handleLocalPickup = async () => {
    setLoading(true);
    setError(false);
  
    try {
      const response = await contactStoreOwner(storeHandle, product._id, email);
      const responseData = await response.json(); // Parse the JSON response from the backend
  
      if (response.ok) {
        // Display the backend message in the success toast
        setTimeout(() => {
          toast.success(responseData.message || 'You request has been forwarded to the seller.');
          handleClose(); // Close the modal on success
          setLoading(false);
        }, 1000);
      } else {
        // Throw an error with the backend message
        throw new Error(responseData.message || 'Failed to message the seller.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      // Display the backend error message (if available) or a default error message
      toast.error(error.message || 'An error occurred. Please try again.');
      setError(true);
      setTimeout(() => {
        setError(false); // Revert error state after 3 seconds
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Contact</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="email">
            <Form.Label>{product ? product.name : ''}</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Form>
        <div className="mt-4">
          {storeAddress && storeAddress.suburb && storeAddress.state && storeAddress.country ? (
            <label>
              {storeAddress.suburb}, {storeAddress.state},{" "}
              {countryLookup[storeAddress.country] || storeAddress.country}
            </label>
          ) : (
            <label>Location not available!</label>
          )}
          {mapUrl && (
            <img
              src={mapUrl}
              alt="Store Approximate Location"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                border: "2px solid #ddd",
              }}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
      <button 
          type="button" 
          className={`btn btn-primary ${error ? 'error' : ''}`} 
          onClick={handleLocalPickup}
          disabled={loading || error}  // Disable the button when loading
        >
          {loading ? (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              &nbsp;Submitting...
            </>
          ) : error ? (
            'Invalid Email'  // Show error text
          ) : (
            'Contact Seller'
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactModal;
