import React from 'react';

function Footer({ storeHandle, absolutePath }) {
  const policyPath = absolutePath ? "/policy" : `/${storeHandle}/policy`;

    return (
        <footer className="footer-wrapper">
            <div className="vp-footer">
            </div>
            <div className="vp-policy">
                <a href={policyPath}>Store Policy</a>
            </div>
            <div className="vp-copyright">
                Powered by <a href='https://sunthar.com' target='_blank'>Sunthar's Super Store</a>
            </div>
        </footer>
    );
}

export default Footer;