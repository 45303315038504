// services/contactService.js

// With React you have to prefix the environment variable with REACT_APP_
const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

export const contactStoreOwner = (storeHandle, productId, email) => {

  const payload = {
    product_id: productId, 
    email: email    
  };

  return fetch(`${STORE_BACKEND_URL}/shipping/pickup/${storeHandle}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
  });
};
