import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { StoreContext } from '../context/StoreContext';
import ReactMarkdown from 'react-markdown';

function StorePolicy() {
  const { storeData, loading: storeLoading, error: storeError } = useContext(StoreContext);

  if (storeLoading) return <div>Loading...</div>;
  if (storeError) return <div>Error: {storeError}</div>;

  return (
    <>
      <Container>
        <div className="store-page-wrapper">
          <div className="hint-container warning">
              <p>
                <strong>PAYMENT AND PRIVACY</strong>
              </p>
              <p>
                Stripe is used to securely handle transactions. Your credit card details are never stored by us. Only your purchase, name, and shipping information are shared by Stripe, so that we can ship your order and provide support. 
                If you have any questions, feel free to reach out!
              </p>
            <ReactMarkdown>
              {storeData.policy && storeData.policy}
            </ReactMarkdown>
          </div>
        </div>
      </Container>
    </>
  );
}

export default StorePolicy;