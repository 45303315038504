import {Container} from 'react-bootstrap';

function Cancel({storeHandle, absolutePath}) {

  //console.log('absolutePath: ' + JSON.stringify(absolutePath));
  const homePath = absolutePath ? "/" : `/${storeHandle}`;

    return (
      <Container>
        <div className="store-page-wrapper">
          <p align="center" style={{'margin-top': '60px'}}>
            <h2 className="p-3">
              We're sorry to see that you canceled your Stripe payment!
            </h2>
            <a className="sunthar-button" href="/">Back to Store</a>
          </p>
        </div>
      </Container>
    )
}

export default Cancel;