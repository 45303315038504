// shippingService.js

// With React you have to prefix the environment variable with REACT_APP_
const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

export const transformShippingData = (shippingResponse) => {
  const { shipping_rates, weight_settings, zone_settings } = shippingResponse;
  
  // Create a map to hold transformed data
  const transformedShippingRates = {};

  // Step 1: Create a zone-to-country map
  const zoneToCountryMap = {};
  zone_settings.forEach((zone) => {
    zone.countries.forEach((countryCode) => {
      zoneToCountryMap[countryCode] = zone.zone_number;
    });
  });

  // Step 2: Map shipping rates per country using zones
  Object.keys(zoneToCountryMap).forEach((countryCode) => {
    const zoneNumber = zoneToCountryMap[countryCode];

    // Get all shipping rates for the current country based on its zone
    const countryShippingRates = shipping_rates.filter(rate => rate.zone === zoneNumber);

    // Combine shipping rates with the corresponding weight class details
    transformedShippingRates[countryCode] = countryShippingRates.map(rate => {
      const weightClassDetails = weight_settings.find(weight => weight.weight_class === rate.weight_class);

      return {
        weight_class: rate.weight_class,
        min_weight: weightClassDetails.min_weight,
        max_weight: weightClassDetails.max_weight,
        amount: rate.amount,
        currency: rate.currency,
        rate_id: rate.rate_id,
        label: rate.label,
        zone: rate.zone, // Optionally include zone if needed
      };
    });
  });

  return transformedShippingRates;
};

export const fetchShippingRates = async (storeHandle) => {
  const url = `${STORE_BACKEND_URL}/shipping/public/${storeHandle}/shipping-rates`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch shipping rates: ${response.statusText}`);
    }
    const shippingResponse = await response.json();

    //console.log ('Shipping Response: ' + JSON.stringify(shippingResponse));

    if (shippingResponse && Object.keys(shippingResponse).length !== 0) {
      const transformedShippingRates = transformShippingData(shippingResponse);

    //console.log ('Transformed Shipping Response: ' + JSON.stringify(transformedShippingRates));

      return transformedShippingRates;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching shipping rates:', error);
    throw error;
  }
};
