// StoreContext.js
import React, { createContext, useState, useEffect } from 'react';
import { fetchStoreData } from '../services/storeService';

// Create the StoreContext
export const StoreContext = createContext();

// StoreProvider component
const StoreProvider = ({ storeHandle = 'sunthar', children }) => {
  const [storeData, setStoreData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to load store data
  const loadStoreData = async (storeHandle) => {
    setLoading(true);
    setError(null);
    try {
      console.log ('StoreProvider storeHandle: ' + storeHandle);
      const response = await fetchStoreData(storeHandle);
      setStoreData(response);
      return response;
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Load store data when storeHandle changes
  useEffect(() => {
    if (storeHandle) {
      loadStoreData(storeHandle);
    }
  }, [storeHandle]);

  return (
    <StoreContext.Provider
      value={{
        storeData,
        loading,
        error,
        loadStoreData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
