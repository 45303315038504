import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { ProductsContext } from '../context/ProductsContext';
import { ShippingContext } from "../context/ShippingContext";
import { StoreContext } from '../context/StoreContext'; // Added import for StoreContext
import ProductCard from '../components/ProductCard';
import WaitlistModal from '../components/WaitlistModal';
import ContactModal from '../components/ContactModal';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function ProductTypePage({storeHandle, absolutePath}) {
  const { type = 'all' } = useParams(); // Default to 'sunthar' if no store provided
  const { productsArray = [], loadAllProducts } = useContext(ProductsContext);
  const { loadShippingRates } = useContext(ShippingContext);
  const { storeData } = useContext(StoreContext); // Extract storeData from StoreContext
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalProducts, setTotalProducts] = useState(0);
  const [showContactModal, setShowContactModal] = useState(false);

  const observer = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await loadAllProducts(storeHandle, type, currentPage); // Await the response properly
        if (response && response.products) {
          // Set products directly during initial load
          setTotalProducts(response.total_products); // Set total products based on response

          console.log('response.products.length: ' + response.products.length);
          console.log('response.total_products: ' + response.total_products);

          setHasMore(response.products.length < response.total_products); // Update setHasMore logic
        }
        await loadShippingRates(storeHandle); // Load shipping rates
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    if (storeHandle) {
      fetchData();
    }
  }, [storeHandle, type]);

  // Observer callback for infinite scroll
  const lastProductElementRef = useCallback(node => {
    if (loadingMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && productsArray.length < totalProducts) {
        setLoadingMore(true);
        setCurrentPage(prevPage => prevPage + 1);
      }
    });

    if (node) observer.current.observe(node);
  }, [loadingMore, hasMore, productsArray.length, totalProducts]);

  // Fetch more products when currentPage changes

  useEffect(() => {
    if (currentPage > 1) {
      const fetchMoreData = async () => {
        try {
          const response = await loadAllProducts(storeHandle, type, currentPage);
          if (response && response.products) {
            console.log('productsArray.length: ' + productsArray.length);
            console.log('response.products.length: ' + response.products.length);
            console.log('response.total_products: ' + response.total_products);
            setHasMore(productsArray.length + response.products.length < response.total_products);
          }
        } catch (err) {
          toast.error(err.message);
        } finally {
          setLoadingMore(false);
        }
      };
  
      fetchMoreData();
    }
  }, [currentPage]);

  const handleImageClick = (product) => {
    console.log ('ABSOLUTE PATH: ' + absolutePath)
    if (absolutePath) {
      navigate(`/product/${product._id}`); // absolute path for path that came through a domain mapping
    } else {
      navigate(`/${storeHandle}/product/${product._id}`); // relative path for path with storeHandle
    }

  };

  const handleWaitlistClick = (product) => {
    setSelectedProduct(product);
    setShowWaitlistModal(true);
  };

  const handleWaitlistModalClose = () => {
    setShowWaitlistModal(false);
  };

  const handleContactClick = (product) => {
    setSelectedProduct(product);
    setShowContactModal(true);
  }

  const handleContactModalClose = () => {
    setShowContactModal(false);
  };

  if (loading && currentPage === 1) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Container>
        <ToastContainer />
        <div className="store-page-wrapper">

          {/* Render Product Type Title from Localization */}
          <div className="type-section">
            <h2 className="product-type-title">
              {storeData?.localization?.['product_type_'+type] || type} {/* Updated to use localization from storeData */}
            </h2>
            <Row xs={1} md={3} lg={4} className="g-4">
              {productsArray.map((product, idx) => {
                if (productsArray.length === idx + 1) {
                  return (
                    <Col ref={lastProductElementRef} key={idx} align="center">
                      <ProductCard
                        product={product}
                        type={type}
                        cartEnabled={storeData.cart_enabled}
                        onImageClick={handleImageClick}
                        onWaitlistClick={handleWaitlistClick}
                        onHandleContactClick={handleContactClick}
                      />
                    </Col>
                  );
                } else {
                  return (
                    <Col key={idx} align="center">
                      <ProductCard
                        product={product}
                        type={type}
                        cartEnabled={storeData.cart_enabled}
                        onImageClick={handleImageClick}
                        onWaitlistClick={handleWaitlistClick}
                        onHandleContactClick={handleContactClick}
                      />
                    </Col>
                  );
                }
              })}
            </Row>
          </div>

          {/* Waitlist Modal */}
          <WaitlistModal
            show={showWaitlistModal}
            handleClose={handleWaitlistModalClose}
            product={selectedProduct}
            storeHandle={storeHandle}
          />

          {/* Local Pickup Modal */}
          <ContactModal
            show={showContactModal}
            handleClose={handleContactModalClose}
            product={selectedProduct}
            storeHandle={storeHandle}
            storeAddress={storeData.address}
          />
        </div>
      </Container>
    </>
  );
}

export default ProductTypePage;
