import {Container} from 'react-bootstrap';

function Success({storeHandle, absolutePath}) {

  //console.log('absolutePath: ' + JSON.stringify(absolutePath));
  const homePath = absolutePath ? "/" : `/${storeHandle}`;

  return (
    <Container>
      <div className="store-page-wrapper">
        <div align="center" style={{marginTop: '60px'}}>
          <h2 className="p-3">
            Thank you for your purchase!
          </h2>
          <a className="sunthar-button" href={homePath}>
            Back to Store
          </a>
        </div>
      </div>
    </Container>
  )
}

export default Success;