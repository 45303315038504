import { Card, Form, Row, Col } from 'react-bootstrap';
import { CartContext } from '../context/CartContext';
import { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductCard.css';

//TODO mouse over the card to give it a tiny smooth zoom

function ProductCard(props) { // props.product is the product we are selling
  const product = props.product;
  const onImageClick = props.onImageClick; 
  const cartEnabled = props.cartEnabled;
  const cart = useContext(CartContext);
  const productCartQuantity = cart.getCartProductQuantity(product._id);

  //console.log(cart.items);
  return (
    <>
      <Card className="product-card">
        <Card.Body>
          {product.isNew && <div className="product-label">new</div>} {/* New product label */}
          {product.isFreeShipping && <Card.Stopper>Free shipping</Card.Stopper>}

          <div className="product-image-container" style={{ textAlign: 'center' }}> {/* Container for image and label */}
          {product.images && product.images.length > 0 ? (
            <img
              className='product-image'
              src={product.images[0].cropped_url} // Use the `url` field of the first image
              onClick={() => onImageClick(product)} 
              alt={product.name}
              style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable image
            />
          ) : (
            <img
              className='product-image'
              src='/placeholder-thumbnail.jpg' // Use the `url` field of the first image
              onClick={() => onImageClick(product)} 
              alt={product.name}
              style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable image
            />
          )}
          </div>
          <div className='product-name'>
            {product.name}
          </div>
          <div className='product-price-stock-section'>
            {product.type === 'affiliate' ? (
              <div style={{ justifyContent: 'space-between' }}>
                <Card.Text style={{ textAlign: 'center' }}>
                  <strong>{cart.formatPrice(product.price, product.currency)}</strong>
                </Card.Text>
              </div>
            ) : product.type === 'showcase' ? (
              <div style={{ justifyContent: 'space-between' }}>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Card.Text>
                  <strong>{cart.formatPrice(product.price, product.currency)}</strong>
                </Card.Text>
                {product.inventory != null && product.inventory <= 0 ? (
                  <Card.Text style={{ color: '#ff4554', textAlign: 'right' }}>
                    Out of Stock
                  </Card.Text>
                ) : (
                  <Card.Text style={{ color: '#b8e986', textAlign: 'right' }}>
                    {product.inventory} in stock
                  </Card.Text>
                )}
              </div>
            )}
          </div>
          {/* Button Section */}
          <div className="product-buttons">
            {product.type === 'affiliate' ? (
              <a href={product.affiliate_link} target="_blank" className="sunthar-add-button" style={{ width: '100%' }}>
                Shop Now!
              </a>
            ) : product.type === 'showcase' ? (
              <a 
                onClick={() => onImageClick(product)} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="sunthar-add-button" 
                style={{ width: '100%' }}
              >
                See Details
              </a>
            ) : (product.type === 'sale' || product.type === 'service') ? (
              product.inventory > 0 ? (
                (product.availability === 'local_only' || !cartEnabled)  ? (
                  <a onClick={() => props.onHandleContactClick(product)} className="sunthar-local-button" style={{ width: '100%' }}>
                    {props.type === 'service' ? 'Contact Technician' : 'Local Pickup'}
                  </a>
                ) : productCartQuantity > 0 ? (
                    <Form as={Row}>
                      <Col xs="3" style={{ paddingRight: '0px' }}>
                        <a onClick={() => cart.removeOneFromCart(product._id)} className="sunthar-remove-button">
                          &mdash;
                        </a>
                      </Col>
                      <Col xs="9">
                        <a onClick={() => cart.addOneToCart(product._id, product.inventory)} className="sunthar-add-button" style={{ width: '100%' }}>
                          Add to Cart
                        </a>
                      </Col>
                    </Form>
                  ) : (
                    <a onClick={() => cart.addOneToCart(product._id, product.inventory)} className="sunthar-add-button" style={{ width: '100%' }}>
                      Add to Cart
                    </a>
                  )
              ) : (
                <a className="sunthar-waitlist-button" style={{ width: '100%' }} onClick={() => props.onWaitlistClick(product)}>
                  Join Waitlist
                </a>
              )
            ) : null}
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default ProductCard;