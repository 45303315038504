import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import NavbarComponent from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Cancel from './pages/Cancel';
import Store from './pages/Store';
import ProductDetails from './pages/ProductDetails';
import Success from './pages/Success';
import ProductTypePage from './pages/ProductTypePage';
import StorePolicy from './pages/StorePolicy';
import CartProvider from './context/CartContext';
import ProductsProvider from './context/ProductsContext';
import ShippingProvider from './context/ShippingContext';
import StoreProvider from './context/StoreContext';

const mainUrl = process.env.REACT_APP_MAIN_URL;

// Hard-coded domain-to-store mapping
const domainToStoreHandleMap = {
  'store.sunthar.com': 'sunthar',
  'store.thanesh.com' : 'sunthar',
  'crts.sunthar.com': 'crts'
};

function App() {
  // Extract the store handle dynamically from the domain mapping
  const hostname = window.location.hostname;
  let storeHandle = domainToStoreHandleMap[hostname] || null;
  const absolutePath = storeHandle !== null;
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    // On initial load, get the theme from localStorage if available, otherwise default to light theme
    const storedTheme = localStorage.getItem('theme');
    return storedTheme ? storedTheme === 'dark' : true; // Default to dark theme if no theme is stored
  });

  if (!storeHandle) {
    // Extract storeHandle from the path if not found in domain mapping
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    //console.log ('pathSegments: ' + JSON.stringify(pathSegments));
    if (pathSegments.length > 0) {
      storeHandle = pathSegments[0];
      //console.log ('pathSegment: ' + storeHandle);
    }
  }

  // Apply the selected theme to the document body
  useEffect(() => {
    if (isDarkTheme) {
      document.body.setAttribute('data-bs-theme', 'dark');
    } else {
      document.body.setAttribute('data-bs-theme', 'light');
    }
  }, [isDarkTheme]);

  if (!storeHandle) {
    // Redirect root requests to beambenders.com if no storeHandle is found
    window.location.href = mainUrl || 'https://beambenders.com';
    return null;
  }

  return (
    <BrowserRouter>
      <ProductsProvider>
        <ShippingProvider>
          <CartProvider storeHandle={storeHandle}>
            <div className="theme-container no-sidebar">
              <Routes>
                {domainToStoreHandleMap[hostname] ? (
                  // Dynamically handle all routes for a given storeHandle (e.g., crts.sunthar.com)
                  <>
                    <Route
                      path="/*"
                      element={
                        <StoreWithStoreProvider storeHandle={storeHandle} absolutePath={absolutePath}>
                          <StoreRouter storeHandle={storeHandle} absolutePath={absolutePath}/>
                        </StoreWithStoreProvider>
                      }
                    />
                  </>
                ) : (
                  // Define other generic routes here
                  <>
                    <Route
                      path="/*"
                      element={
                        <StoreWithStoreProvider storeHandle={storeHandle} absolutePath={absolutePath}>
                          <PageRouter storeHandle={storeHandle} absolutePath={absolutePath}/>
                        </StoreWithStoreProvider>
                      }
                    />
                  </>
                )}
              </Routes>
            </div>
          </CartProvider>
        </ShippingProvider>
      </ProductsProvider>
    </BrowserRouter>
  );
}

// This component handles routing for each store using the storeHandle context
function StoreRouter({ storeHandle, absolutePath }) {
  return (
    <Routes>
      <Route path="/" element={<Store storeHandle={storeHandle}/>} />
      <Route path="/product/:id" element={<ProductDetails storeHandle={storeHandle}/>} />
      <Route path="/policy" element={<StorePolicy storeHandle={storeHandle}/>} />
      <Route path="/success" element={<Success storeHandle={storeHandle} absolutePath={absolutePath}/>} />
      <Route path="/cancel" element={<Cancel storeHandle={storeHandle} absolutePath={absolutePath}/>} />
      <Route path="/:type" element={<ProductTypePage storeHandle={storeHandle} absolutePath={absolutePath}/>} />
      <Route path="/:type/:page" element={<ProductTypePage storeHandle={storeHandle} absolutePath={absolutePath}/>} />
    </Routes>
  );
}

// This component handles routing for each store using the storeHandle context
function PageRouter({ storeHandle, absolutePath }) {
  return (
    <Routes>
      <Route path="/:storeHandle" element={<Store storeHandle={storeHandle}/>} />
      <Route path="/:storeHandle/product/:id" element={<ProductDetails storeHandle={storeHandle}/>} />
      <Route path="/:storeHandle/policy" element={<StorePolicy storeHandle={storeHandle}/>} />
      <Route path="/:storeHandle/success" element={<Success storeHandle={storeHandle} absolutePath={absolutePath}/>} />
      <Route path="/:storeHandle/cancel" element={<Cancel storeHandle={storeHandle} absolutePath={absolutePath}/>} />
      <Route path="/:storeHandle/:type" element={<ProductTypePage storeHandle={storeHandle} absolutePath={absolutePath}/>} />
      <Route path="/:storeHandle/:type/:page" element={<ProductTypePage storeHandle={storeHandle} absolutePath={absolutePath}/>} />
    </Routes>
  );
}

// StoreWithStoreProvider for wrapping routes with storeHandle context
function StoreWithStoreProvider({ children, storeHandle, absolutePath }) {
  return (
    <StoreProvider storeHandle={storeHandle}>
      <NavbarComponent storeHandle={storeHandle} absolutePath={absolutePath} />
      {children}
      <Footer storeHandle={storeHandle} absolutePath={absolutePath} />
    </StoreProvider>
  );
}

export default App;
