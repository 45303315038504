import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useContext } from 'react';
import { CartContext } from '../context/CartContext';
import { ProductsContext } from '../context/ProductsContext';
import { StoreContext } from '../context/StoreContext';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import WaitlistModal from '../components/WaitlistModal';
import ContactModal from '../components/ContactModal';
import countryLookup from '../utils/countryLookup';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductDetailsPage.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; 

const ProductDetailsPage = ({storeHandle}) => {
  const { id } = useParams();
  const { loadProduct, product, setProduct } = useContext(ProductsContext);
  const { storeData, loadStoreData } = useContext(StoreContext);
  const cart = useContext(CartContext);
  // State to store the current product details
  //const [product, setProduct] = useState(null);
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapUrl, setMapUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log ('Product ID: ' + id);
        const product = await loadProduct(id);
        setProduct(product);

        // Load Store Data - this is especially required if a user is directly visiting the ProductDetails page
        if (storeHandle) {
          const store = await loadStoreData(storeHandle);
          //console.log('Store loaded: ' + JSON.stringify(store));

          if (store.address && store.address.suburb && store.address.state && store.address.country) {
            const addressQuery = `${store.address.suburb}, ${store.address.state}, ${store.address.country}`;
            const size = "400x200"; // Map size (width x height in pixels)
            const zoom = 12; // Adjust zoom level for approximate location
            const scale = 2; // High resolution for sharper images
      
            const marker = `markers=color:0xf5a623|label:Pickup`
      
            // Convert styles to Static Maps format
            const style = [
              "feature:all|element:labels.text.fill|gamma:0.01|lightness:20|weight:1.39|color:0xffffff",
              "feature:all|element:labels.text.stroke|weight:0.96|saturation:9|visibility:on|color:0x000000",
              "feature:all|element:labels.icon|visibility:off",
              "feature:all|element:geometry|color:0x431C53", // Dark reddish-purple for all geometry
              "feature:road|element:geometry|color:0xff4c4c", // Bright red for roads
              "feature:road|element:geometry.stroke|color:0xea3377", // Pinkish-red for road borders
              "feature:water|element:geometry|color:0xff4c4c", // Bright red for water
              "feature:landscape|element:geometry|color:0x431C53", // Pinkish-red for landscape
              "feature:poi|element:geometry|color:0x8d1642", // Dark reddish-purple for points of interest
              "feature:poi.park|element:geometry|lightness:20|saturation:-20",
            ].join("&style=");
            
            const url = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
              addressQuery
            )}&zoom=${zoom}&size=${size}&scale=${scale}&maptype=roadmap&${marker}|${encodeURIComponent(
              addressQuery
            )}&style=${style}&key=${GOOGLE_MAPS_API_KEY}`;
      
            setMapUrl(url);
          }

        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleContactClick = (product) => {
    setProduct(product);
    setShowContactModal(true);
  }

  const handleContactModalClose = () => {
    setShowContactModal(false);
  };

  const handleWaitlistClick = (product) => {
    setProduct(product);
    setShowWaitlistModal(true);
  };

  const handleWaitlistModalClose = () => {
    setShowWaitlistModal(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
      <ToastContainer />
      <div className="product-details-wrapper">
        {product ? (
          <div className="row">
            {/* Main Content */}
            <div className="col-lg-8">
              {/* Product Images with Captions */}
              {product.images && product.images.map((image, idx) => (
                <div key={idx} className="product-image-section">
                  <img src={image.compressed_url} alt={`Product Image ${idx + 1}`} className="product-image mb-2" />
                  {image.caption && 
                    <span className="image-caption">
                      <div className="markdown-container">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{image.caption}</ReactMarkdown>
                      </div>
                    </span>}
                </div>
              ))}
              {/* YouTube Videos */}
              {product.videos && product.videos.map((video, idx) => (
                <div key={idx} className="product-video-wrapper">
                  <div className="product-video-container">
                    <iframe
                      src={`https://www.youtube.com/embed/${video}`}
                      className="product-video"
                      title={`Product Video ${idx + 1}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              ))}
            </div>
            {/* Side Rail */}
            <div className="col-lg-4">
              <div className="product-details-siderail">
                {/* Product Details */}
                <div className="product-details">
                  <h3 className="product-title">{product.name}</h3>
                  {(product.type === 'showcase' && product.author) && (
                    <>
                      <small>
                        Author: {product.author}
                      </small>
                      <br />
                    </>
                  )}
                  <small>
                    {new Date(product.created_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}{/*new Date(product.created_at).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })*/}
                  </small>
                  <br />
                  <br />
                  <strong>Description:</strong>
                  <div className="markdown-container">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{product.description}</ReactMarkdown>
                  </div>
                  {(product.type === 'sale' || product.type === 'service') && (
                    <>
                      <p className='product-price'>
                        <strong>Price:</strong> {cart.formatPrice(product.price, product.currency)}
                      </p>
                      {(product.type === 'sale') && (
                      <p>
                        <strong>Inventory:</strong> {product.inventory > 0 ? `${product.inventory} in stock` : 'Out of Stock'}
                      </p>
                      )}
                    </>
                  )}
                </div>

                {/* Product Actions */}
                {(product.type === 'sale' || product.type === 'service') && (
                  <div className="product-actions">
                    {product.inventory > 0 ? (
                      (product.availability === 'local_only' || !storeData.cart_enabled) ? (
                        <>
                          <div className="mb-3">
                            {storeData && storeData.address && storeData.address.suburb && storeData.address.state && storeData.address.country ? (
                              <label>
                                {storeData.address.suburb}, {storeData.address.state},{" "}
                                {countryLookup[storeData.address.country] || storeData.address.country}
                              </label>
                            ) : (
                              <label>Location not available!</label>
                            )}
                            {mapUrl && (
                              <img
                                src={mapUrl}
                                alt="Store Approximate Location"
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  borderRadius: "10px",
                                  border: "2px solid #ddd",
                                }}
                              />
                            )}
                          </div>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleContactClick(product)}
                          >
                            {(product.type === 'service') ? 'Contact' : 'Local Pickup'} 
                          </button>
                        </>
                      ) : (
                        <button
                        className="btn btn-primary"
                        onClick={() => cart.addOneToCart(product._id, product.inventory)}
                        >
                          Add to Cart
                        </button>
                      )
                    ) : (
                      <a className="sunthar-waitlist-button" style={{ width: '100%', borderRadius: '5px' }} onClick={() => handleWaitlistClick(product)}>
                        Join Waitlist
                      </a>
                    )}
                  </div>
                )}
                {product.type === 'affiliate' && product.affiliate_link && (
                  <div className="product-actions">
                    <a href={product.affiliate_link} target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-primary">
                        Shop Now!
                      </button>
                    </a>
                  </div>
                )}

                <br />
                <small><strong>Last Updated: </strong>
                  {new Date(product.updated_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}{/*new Date(product.updated_at).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })*/}
                </small>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading product details...</p>
        )}
        {/* Waitlist Modal */}
        <WaitlistModal
          show={showWaitlistModal}
          handleClose={handleWaitlistModalClose}
          product={product}
          storeHandle={storeHandle}
        />
        {/* Local Pickup Modal */}
        <ContactModal
          show={showContactModal}
          handleClose={handleContactModalClose}
          product={product}
          storeHandle={storeHandle}
          storeAddress={storeData && storeData.address ? storeData.address : {}}
        />
      </div>
    </Container>
  );

};

export default ProductDetailsPage;
